<template>
    <div id="sidebar-button" class="flex flex-col">
        <router-link
            :to="{ name: pageName, params: { role: role } }"
            :append="false"
            class="
                text-gray-300
                w-full
                group
                px-2
                py-2
                rounded-md
                hover:bg-gray-700 hover:text-white
                focus:outline-none
                text-sm
                desktop:text-base
                font-medium
            "
        >
            <div><slot></slot></div>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "SidebarBtn",
    props: {
        pageName: String,
        role: Number
    }
}
</script>

<style scoped>
a.active {
    background: #fbbf24;
    color: white;
}
</style>
