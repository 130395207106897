<template>
    <nav class="mt-5 px-2 space-y-1">
        <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
        <SidebarBtn
            @click="onPress"
            :page-name="'PDashboard'"
            :role="user.portal_user_role_level"
            ><slot
                ><div class="flex items-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="
                            mr-3
                            stroke-current
                            icon icon-tabler icon-tabler-dashboard
                        "
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#ffffff"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="12" cy="13" r="2" />
                        <line x1="13.45" y1="11.55" x2="15.5" y2="9.5" />
                        <path d="M6.4 20a9 9 0 1 1 11.2 0z" />
                    </svg>
                    Party Room Dashboard
                </div></slot
            ></SidebarBtn
        >

        <SidebarBtn
            @click="onPress"
            :page-name="'Upcomingbooking'"
            :role="user.portal_user_role_level"
            ><slot
                ><div class="flex items-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="
                            mr-3
                            stroke-current
                            icon icon-tabler icon-tabler-notes
                        "
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#ffffff"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <rect x="5" y="3" width="14" height="18" rx="2" />
                        <line x1="9" y1="7" x2="15" y2="7" />
                        <line x1="9" y1="11" x2="15" y2="11" />
                        <line x1="9" y1="15" x2="13" y2="15" />
                    </svg>
                    {{ $t("sidebar.upcoming_booking") }}
                </div></slot
            ></SidebarBtn
        >

        <SidebarBtn
            @click="onPress"
            :page-name="'Pastbooking'"
            :role="user.portal_user_role_level"
            ><slot
                ><div class="flex items-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="
                            mr-3
                            stroke-current
                            icon icon-tabler icon-tabler-history
                        "
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#ffffff"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <polyline points="12 8 12 12 14 14" />
                        <path d="M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5" />
                    </svg>
                    {{ $t("sidebar.past_booking") }}
                </div></slot
            ></SidebarBtn
        >

        <SidebarBtn
            @click="onPress"
            :page-name="'Setting'"
            :role="user.portal_user_role_level"
            ><slot
                ><div class="flex items-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="
                            mr-3
                            stroke-current
                            icon icon-tabler icon-tabler-settings
                        "
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#ffffff"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path
                            d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"
                        />
                        <circle cx="12" cy="12" r="3" />
                    </svg>
                    {{ $t("sidebar.setting") }}
                </div></slot
            ></SidebarBtn
        >

        <button
            v-if="roles.length > 1"
            @click="onModeChange"
            class="
                text-gray-300
                w-full
                group
                px-2
                py-2
                rounded-md
                hover:bg-gray-700 hover:text-white
                focus:outline-none
                text-sm
                desktop:text-base
                font-medium
                flex
                items-center
            "
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                    mr-3
                    stroke-current
                    icon icon-tabler icon-tabler-switch-horizontal
                "
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#ffffff"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <polyline points="16 3 20 7 16 11" />
                <line x1="10" y1="7" x2="20" y2="7" />
                <polyline points="8 13 4 17 8 21" />
                <line x1="4" y1="17" x2="13" y2="17" />
            </svg>
            Hotel Portal
        </button>

        <button
            @click="logout"
            class="
                text-gray-300
                w-full
                group
                px-2
                py-2
                rounded-md
                hover:bg-gray-700 hover:text-white
                focus:outline-none
                text-sm
                desktop:text-base
                font-medium
                flex
                items-center
            "
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-logout mr-3 stroke-current"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#ffffff"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path
                    d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"
                />
                <path d="M7 12h14l-3 -3m0 6l3 -3" />
            </svg>
            {{ $t("sidebar.logout") }}
        </button>
    </nav>
</template>

<script>
import SidebarBtn from "@/components/SidebarBtn"
export default {
    name: "PartyRoomPageList",
    components: { SidebarBtn },
    props: {
        user: { required: true, type: Object },
        roles: { required: false, type: Array }
    },
    emits: ["onPress", "onModeChange"],
    methods: {
        onPress() {
            this.$emit("onPress")
        },
        onModeChange() {
            this.$emit("onModeChange")
        },
        logout() {
            this.$auth0.logout({ returnTo: window.location.origin })
        }
    }
}
</script>

<style scoped></style>
